<template>
  <v-container>
    <div class="mt-12" style="height: calc(100vh - 190px);">
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-avatar size="250">
            <v-container>
              <v-img
                :src="
                  store_avatar
                    ? store_avatar
                    : require('@/assets/avatar_placeholder.png')
                "
              >
              </v-img>
            </v-container>
          </v-avatar>
        </v-col>
        <v-col cols="12" class="text-h5 text-center">
          Bem vindo(a) <strong>{{ usuario_name }}</strong
          >, basta clicar e vamos trabalhar!
        </v-col>
        <v-row class="">
          <v-col>
            <v-btn
              @click="goToDados"
              outlined
              block
              color="secondary"
              class="white--text"
              x-large
            >
              Meus Dados
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="goToClientes"
              block
              outlined
              color="secondary"
              class="white--text"
              x-large
            >
              Clientes
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="goToCalendario"
              block
              outlined
              color="secondary"
              class="white--text"
              x-large
            >
              Calendário
            </v-btn>
          </v-col>

          <v-col>
            <v-btn
              @click="goToAtividades"
              outlined
              block
              color="secondary"
              class="white--text"
              x-large
            >
              Atividades
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="goToComentario"
              block
              outlined
              color="secondary"
              class="white--text"
              x-large
            >
              Comentários
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Home",

  // components: {
  //   Notificacao: () => import("@/components/notificacao/Notificacao.vue"),
  // },

  data() {
    return {
      apiUrl: null,
    };
  },

  computed: {
    ...mapState("Usuario", {
      usuario_name: (state) => state.usuario.name,
      store_avatar: (state) => state.avatar,
    }),
  },

  methods: {
    goToDados() {
      this.$router.push({ path: "/colaborador/dados" });
    },
    goToClientes() {
      this.$router.push({ path: "/colaborador/clientes" });
    },
    goToAtividades() {
      this.$router.push({ path: "/colaborador/atividades" });
    },
    goToCalendario() {
      this.$router.push({
        path: "/colaborador/calendario-atividades",
      });
    },
    goToComentario() {
      this.$router.push({
        path: "/colaborador/comentarios",
      });
    },
  },

  mounted() {
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
  },
};
</script>

<style lang="scss"></style>
